#image-track > .image {
    width: 40vmin;
    height: 40vmin;
    object-fit: cover;
    object-position: center;

    transition: zoom 800ms ease, 
}
#image-track > .image:hover {
    border-style: dotted;
    border-color: white;
    object-fit: cover;
    object-position: center;
}
#image-track {
    display: flex;
    gap: 4vmin;
    position: absolute;
    top: 50%; 
    transform: translate(0, -50%);
    left: 50%;
}