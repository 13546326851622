.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#menu {
  height: 100vh;
  display: flex;
  align-items: center;
}
#menu[data-active-index="0"] > #menu-background-pattern {
  background-position: 0% -20%;
}
#menu[data-active-index="1"] > #menu-background-pattern {
  background-position: 0% -40%;
}
#menu[data-active-index="2"] > #menu-background-pattern {
  background-position: 0% -60%;
}
#menu[data-active-index="3"] > #menu-background-pattern {
  background-position: 0% -80%;
}
#menu[data-active-index="4"] > #menu-background-pattern {
  background-position: 0% -100%;
}
#menu[data-active-index="0"] > #menu-background-image {
  background-position: 0% 45%;
  background-image: url(./assets/spinner32.png);
}
#menu[data-active-index="1"] > #menu-background-image {
  background-position: 0% 50%;
  background-image: url(./assets/spinner08.png);
}
#menu[data-active-index="2"] > #menu-background-image {
  background-position: 0% 55%;
  background-image: url(./assets/spinner16.png);
}
#menu[data-active-index="3"] > #menu-background-image {
  background-position: 0% 60%;
  background-image:url(./assets/spinner24.png);
}
#menu[data-active-index="4"] > #menu-background-image {
  background-position: 0% 65%;
  background-image:url(./assets/spinner32.png);
}
.menu-item {
  color: white;
  font-size: clamp(1rem, 2vw, 2rem);
  text-align: left;
  font-family: "Ibarra Real Nova", sans-serif;
  display: block;
  cursor: -moz-pointer; 
  cursor: -webkit-pointer; 
  cursor: pointer;
  text-decoration: none;
  padding: clamp(0.25rem, 0.5vw, 1rem);
}

#menu-items {
  margin-left: clamp(4rem, 20vw, 48rem);
  position: relative;
  z-index: 2;
}
#menu-items:hover > .menu-item {
  opacity: 0.3;
}

#menu-items:hover > .menu-item:hover {
  opacity: 1;
}
#menu-items:hover ~ #menu-background-pattern {
  background-size: 10vmin 10vmin;
  opacity: 0.4;
}
#menu-items:hover ~ #menu-background-image {
  background-size: 100vmax;
  opacity: 0.15;
}

#menu-background-pattern {
  height: 100vh;
  width: 100vw;

  background-image: url(./assets/cross.png);
  background-position: 0% 0%;
  background-size: 11vmin 11vmin;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  opacity: 0.5;
  transition: opacity 800ms ease, 
  background-position 800ms ease,
  background-size 800ms ease;
}

#menu-background-image {
  height: 100vh;
  width: 100vw;

  background-image: url(./assets/spinner32.png);
  background-position: center 40%;
  background-size: 110vmax;
  background-repeat: no-repeat;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
  opacity: 0.2;

  transition: opacity 800ms ease, 
  background-position 800ms ease,
  background-image 800ms ease,
  background-size 800ms ease;
}